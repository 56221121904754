// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-index-js": () => import("./../../src/pages/menu/index.js" /* webpackChunkName: "component---src-pages-menu-index-js" */),
  "component---src-templates-menu-items-page-js": () => import("./../../src/templates/menu-items-page.js" /* webpackChunkName: "component---src-templates-menu-items-page-js" */),
  "component---src-templates-post-page-js": () => import("./../../src/templates/post-page.js" /* webpackChunkName: "component---src-templates-post-page-js" */)
}

